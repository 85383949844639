var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"68e4d6b1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

import { extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations'

import { envBasedSentryTracingSampleRate } from './sentry.config.utils'
import { Environment } from './src/const'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ENV = process.env.NEXT_PUBLIC_APP_ENV

Sentry.init({
    dsn: SENTRY_DSN,
    enabled: ENV !== Environment.local,
    environment: ENV,
    ignoreErrors: [
        'Failed to load static props',
        'Loading initial props cancelled',
        'ResizeObserver loop completed with undelivered notifications',
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            blockAllMedia: false,
            maskAllText: false,
            networkDetailAllowUrls: [window.location.origin],
        }),
        extraErrorDataIntegration({ depth: 10 }),
        // captures errors on failed requests from Fetch and XHR and attaches request and response information
        httpClientIntegration(),
    ],
    // Use the below sample rate to sample sessions when an error occurs.
    // The integration will begin buffering the replay and will only upload it to Sentry if an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Session replays are set to a 0% sample rate.
    // Sentry session replays are used exclusively for error occurrences.
    replaysSessionSampleRate: 0,

    tracesSampleRate: envBasedSentryTracingSampleRate(ENV),

    // Control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/[^.]+\.envdevel\.com\/api\/.*/,
        /^https:\/\/[^.]+\.envstage\.com\/api\/.*/,
        /^https:\/\/[^.]+\.engelvoelkers\.com\/api\/.*/,
        /^https:\/\/[^.]+\.engelvoelkers\.vercel\.app\/api\/.*/,
    ],
})
